import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import EmailSignup from "../components/EmailSignup"

export default function MnemonicsBookPage(props) {
  const { data } = props
  const siteTitle = data.site.siteMetadata.title
  const fluid = data.bookCover.childImageSharp.fluid
  return (
    <Layout location={props.location} title={siteTitle}>
      <SEO title="Mnemonics for Chinese Characters"></SEO>
      <div className="flex flex-col mb-5">
        <div className="text-center uppercase font-semibold text-gray-700 tracking-wider">
          - Coming Soon -
        </div>
        <div className="flex flex-wrap text-center">
          <div>
            <h1 className="my-3 sm:my-0 text-3xl sm:text-5xl font-semibold leading-tight">
              {" "}
              Learn Chinese Characters{" "}
              <span className="text-indigo-700">
                <span className="font-bold">Faster</span> Than Ever Before
              </span>
            </h1>
            <p>
              A companion to <i>Remembering Simplified Hanzi</i> by James Heisig
              to help you learn Chinese characters in ⚡lightning⚡ speed.
            </p>
          </div>
          <div className="w-full flex justify-center">
            <ul className="mt-5 mx-auto  ">
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="h-8 w-8 mr-2"
                >
                  {/* <circle
                cx={12}
                cy={12}
                r={10}
                className="text-white fill-current"
              /> */}
                  <path
                    className="fill-current text-indigo-800"
                    d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                  />
                </svg>
                Over{" "}
                <span className="text-indigo-800 font-bold mx-1">
                  2,500 mnemonics{" "}
                </span>{" "}
                for Chinese characters
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="h-8 w-8 mr-2"
                >
                  {/* <circle
                cx={12}
                cy={12}
                r={10}
                className="text-white fill-current"
              /> */}
                  <path
                    className="fill-current text-indigo-800"
                    d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                  />
                </svg>
                <p className="text-left leading-snug">
                  <span className="text-indigo-800 font-bold">Save time</span>{" "}
                  writing your own stories and{" "}
                  <span className="inline text-indigo-800 font-bold">
                    focus on memorization
                  </span>{" "}
                </p>
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="h-8 w-8 mr-2"
                >
                  {/* <circle
                cx={12}
                cy={12}
                r={10}
                className="text-white fill-current"
              /> */}
                  <path
                    className="fill-current text-indigo-800"
                    d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                  />
                </svg>
                <p className="text-left leading-snug">
                  <span className="text-indigo-800 font-bold">100+</span> pages
                  of content
                </p>
              </li>
              <li className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  className="h-8 w-8 mr-2"
                >
                  {/* <circle
                cx={12}
                cy={12}
                r={10}
                className="text-white fill-current"
              /> */}
                  <path
                    className="fill-current text-indigo-800"
                    d="M10 14.59l6.3-6.3a1 1 0 0 1 1.4 1.42l-7 7a1 1 0 0 1-1.4 0l-3-3a1 1 0 0 1 1.4-1.42l2.3 2.3z"
                  />
                </svg>
                <p className="text-left leading-snug">
                  <span className="text-indigo-800 font-bold">
                    100% satisfaction guarantee.
                  </span>{" "}
                  If you're not satisified, I'll refund your purchase.
                </p>
              </li>
            </ul>
          </div>
          <div className="mx-auto my-6 flex flex-col items-center">
            <p>Subscribe to receive updates on the progress of the book! 👇</p>
            <EmailSignup></EmailSignup>
          </div>
          {/* <div className="hidden sm:flex my-3 w-full shadow-2xl overflow-hidden">
            <Image
              fluid={fluid}
              alt={"Chinese Book Cover"}
              style={{
                marginRight: `20px`,
                marginBottom: 0,
                width: `100%`,
                height: `100%`,
                borderRadius: `2px`,
              }}
            />
          </div> */}
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    bookCover: file(absolutePath: { regex: "/Cover Page.png/" }) {
      childImageSharp {
        fluid(maxWidth: 550, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
